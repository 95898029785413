import Vue from "vue";
import Vuelidate from "vuelidate";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";

import { PiniaVuePlugin } from 'pinia'
import pinia from '@/plugins/pinia'

import App from "./App.vue";

// Importing the global css file
import "./assets/css/global.css";

Vue.use(PiniaVuePlugin)
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
