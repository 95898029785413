<template>
  <div>
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted, onUnmounted } from "vue";
import axios from "axios";
import { AuthenticationApi } from "@/api";
import TresPortalBaseAxiosHelper from "@/util/axios";

import { useInactivity } from "@/composables/useInactivity";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";

const Component = defineComponent({
  setup() {
    const root = getCurrentInstance()?.proxy;
    const authStore = useAuthStore();
    const { accessToken, refreshToken } = storeToRefs(authStore)
    const api = new AuthenticationApi();
    const { init, destroy } = useInactivity()

    const refreshAccessToken = async () => {
      try {
        const { data } = await api.authenticationRefreshAccessToken({
          accessToken: accessToken.value || "",
          refreshToken: refreshToken.value || "",
          }
        );
        authStore.refresh(data);
        return data?.accessToken || "";
      } catch {
        authStore.logOut();
        root?.$router.push({
          name: "login",
          query: {
            trigger: "timeout",
          },
        })
        return "";
      }
    };

    const axiosHelper = new TresPortalBaseAxiosHelper(
      axios,
      refreshAccessToken
    );
    axiosHelper.setup();

    onMounted(() => {
      init()
    })

    onUnmounted(() => {
      destroy()
    })

    return {};
  },
});

export default Component;
</script>
