import Router from "@/router";
import { ref, watch } from "vue";

export function useInactivity() {
  const events = ["mousedown", "scroll", "keydown", "resize"]
  const isInactive = ref(false)
  const timeout = ref<any | null>(null)

  /**
   * Initialize the inactivity composable
   */
  function init() {
    setInactivity(false)
    createEventWatchers()
    resetTimer()
  }

  /**
   * Destroy the inactivity composable
   */
  function destroy() {
    setInactivity(false)
    destroyEventWatchers()
  }

  /**
   * Set inactivity
   */
  function setInactivity(state: boolean) {
    isInactive.value = state
  }

  /**
   * Reset Timeout
   */
  function resetTimer() {
    setInactivity(false)

    if (timeout.value) {
      clearTimeout(timeout.value)
    }

    // timeout is 60 minutes as milliseconds
    const timeoutMs = 60 * 60 * 1000 // 60 minutes
    // const timeoutMs = 10 * 1000 // for testing purposes, time is set to 10 seconds

    timeout.value = setTimeout(() => {
      setInactivity(true)
    }, timeoutMs)
  }

  /**
   * Create event watchers for each event
   */
  function createEventWatchers() {
    events.forEach(event => {
      window.addEventListener(event, resetTimer)
    })
  }

  /**
   * Destroy event watchers for each event
   */
  function destroyEventWatchers() {
    events.forEach(event => {
      window.removeEventListener(event, resetTimer)
    })
  }

  /**
   * Watch for changes in isInactive when isInactive is true redirect to logout
   */
  watch(isInactive, () => {
    destroyEventWatchers()
    Router.push({
      path: "/auth/logout",
      query: {
        trigger: "timeout",
      }
    })
  })

  return {
    init,
    destroy,
  }
}